export const MINUTES_IN_HOUR = 60;
export const HOURS_IN_DAY = 24;
export const MS_IN_DAY = 86_400_000;
export const MS_IN_HOUR = 3_600_000;
export const THOUSAND_DIVIDER = 1_000;
export const MILLION_DIVIDER = 1_000_000;
export const BILLION_DIVIDER = 1_000_000_000;

// Scenario gantt base url
export const SCENARIO_GANTT_ROOT_URL = "process/view/gantt";

// Scenario process validation
export const SCENARIO_NEW_STATUS = "NEW";
export const SCENARIO_PERT_STATUS = "PERT";
export const SCENARIO_GANTT_STATUS = "GANTT";
export const SCENARIO_METRICS_STATUS = "METRICS";
export const SCENARIO_APPROVAL_STATUS = "APPROVAL";
export const SCENARIO_APPROVED_STATUS = "APPROVED";
export const SCENARIO_REJECTED_STATUS = "REJECTED";
export const SCENARIO_PENDING_STATUS = "PENDING";
export const RESOURCE_USAGE_STATUS = "PREPARATION";

// Project lifecycle status
export const PROJECT_BACKLOG_STATUS = "BACKLOG"; // EXEC TO BACKLOG
export const PROJECT_ARCHIVED_STATUS = "ARCHIVED"; // CLOSED TO ARCHIVED
export const PROJECT_ONHOLD_STATUS = "ONHOLD"; // STOPPED TO ONHOLD
export const PROJECT_PROSPECT_STATUS = "PROSPECT";
export const PROJECT_CANCELED_STATUS = "CANCELED";
export const PROJECT_CLOSED_STATUS = "CLOSED";

// Resource usage status
export const RESOURCE_USAGE_DEMAND = "DEMAND";
export const RESOURCE_USAGE_ACTUALS = "ACTUALS";
export const RESOURCE_USAGE_CAPACITY = "CAPACITY";
export const RESOURCE_USAGE_AVAILABILITY = "AVAILABILITY";
export const RESOURCE_USAGE_PLANNED = "BUDGETED";
export const RESOURCE_USAGE_REMAINING = "REMAINING";
export const RESOURCE_USAGE_ALLOCATION = "ALLOCATION";

// Structure type
export const INDEFINITE_SCOPE = "INDEFINITE";
export const DEFINITE_SCOPE = "DEFINITE";
export const IMPORTED_SCOPE = "IMPORTED";
export const CONVERTIBLE_SCOPE = "CONVERTIBLE";

export const MANUAL_PC = "MANUAL";
export const AUTOMATIC_PC = "AUTOMATIC";
export const FULLY_MANUAL_PC = "FULLY_MANUAL";

export const FAVORITE_STRUCTURE = "FAVORITE";
export const PROJECT_STRUCTURE = "PROJECT";

// Block type
export const LEVEL_BLOCK = "LEVEL";
export const STRUCTURE_BLOCK = "STRUCTURE";
export const SEQUENCE_BLOCK = "SEQUENCE";
export const SCOPE_BLOCK = "SCOPE";
export const HEAD_BLOCK = "HEAD";

// Resources
export const RESOURCE_TYPE_INTERNAL = "INTERNAL";
export const RESOURCE_TYPE_EXTERNAL = "EXTERNAL";
export const RESOURCE_TYPE_INDIVIDUAL = "COLLABORATOR";

export const RESOURCE_DEFAULT_GROUP = "NONE";

// Dimension types
export const WORKLOAD_DIMENSION_TYPE = "WORKLOAD";
export const CONSUMABLE_DIMENSION_TYPE = "CONSUMABLE";

// Scenario Decision Making Process type
export const SCENARIO_DMP = " DECISION-MAKING PROCESS";
export const PREPARATION_PROCESS = "PREPARATION";
export const EXECUTION_PROCESS = "EXECUTION";

// SChedule view
export const GANTT_VIEW = "gantt";
export const TIME_VIEW = "time";
export const WORK_VIEW = "work";
export const COST_VIEW = "cost";
export const RESOURCE_VIEW = "resource";

// Max constants
export const MAX_INPUT_LENGTH = 60;
export const MAX_DESCRIPTION_LENGTH = 250;
export const MAX_SCENARIO_DESCRIPTION_LENGTH = 500;
export const MAX_NAME_LENGTH = 100;
export const MAX_GANTT_VIRTUALIZATION_ACTIVATION = 60;
export const MAX_GANTT_DEPENCY_DESACTIVATION = 80;

// Intermediate max constants
export const INTERMEDIATE_MAX_INPUT_LENGTH = 20;
export const INTERMEDIATE_INPUT_LENGTH = 6;

// Max constants
export const BLOCK_WIDTH = 520;
export const BLOCK_HEIGHT = 80;

// Min constants
export const MIN_INPUT_LENGTH = 3;

// Filter period in days
export const USAGE_DAYS_LOOK_AHEAD = 90;
export const TASK_DAYS_LOOK_AHEAD = 20;

//Page size
export const HEIGHT_FOR_TABLE = "100vh";

export const SUMMARY_TYPE = "S";
export const MILESTONE_TYPE = "M";
export const REGULAR_TYPE = "R";
export const TASK_TYPE = "T";

// INPUT TYPE
export const DUPLICATOR = "DUPLICATOR";
export const MULTIPLIER = "MULTIPLIER";
export const DIVIDER = "DIVIDER";
export const CAPEX_INPUT_TYPE = "CAPEX";
export const CONTRACTUAL_DATE_TYPE = "CONTRACTUAL_DATE";

// Pivot chart
export const ENABLE_PIVOT_VIRTUALIZATION = false;

// objective status

export const OBJECTIVE_IN_PROGRESS = "IN PROGRESS";
export const OBJECTIVE_AT_RISK = "AT RISK";
export const OBJECTIVE_NOT_STARTED = "NOT STARTED";
export const OBJECTIVE_REACHED = "REACHED";
export const OBJECTIVE_NOT_REACHED = "NOT REACHED";

export const DATE_DEFAULT_FORMAT = "yyyy-MM-dd";

// 2 Format Digit : 0 is a template, it doesn't mean anything
export const TWO_DECIMALS_FORMAT = "0.##";

export const AAS_PREFIX =
  window.location.hostname === "localhost" ? "/aas" : "/api/aas";
export const PDA_PREFIX =
  window.location.hostname === "localhost" ? "/pda" : "/api/pda";
export const MLHUB_PREFIX =
  window.location.hostname === "localhost" ? "/mlhub" : "/api/mlhub";
export const APIFORMATTER_PREFIX =
  window.location.hostname === "localhost" ? "/af" : "/api/af";
export const PDM_PREFIX = "/api/pdm";

// jwtToken
export const JWT_TOKEN = "jwtToken";

// gantt scenario
export const GANTT_SCENARIO_FILTERS = "ganttScenarioFilters-V5";

// project dashboard filter
export const PROJECT_DASHBOARD_FILTER = "projectDashboardFilter";

// portfolio dashboard timeline
export const COLUMNS_FOR_TIMELINE = "columnsForTimeline-V8";
export const TIMELINE_FILTERS = "timelineFilters-V2";
export const FULL_TIMELINE_PROJECTS_FILTER = "fullTimelineProjectsFilter-V2";
export const FULL_TIMELINE_YEAR_FILTER = "fullTimelineYearFilter-V2";
export const FULL_TIMELINE_TAGS_FILTER = "fullTimelineTagsFilter-V2";
export const CUSTOM_TIMELINE_PROJECTS_FILTER =
  "customTimelineProjectsFilter-V2";
export const CUSTOM_TIMELINE_YEAR_FILTER = "customTimelineYearFilter-V2";
export const CUSTOM_TIMELINE_TAGS_FILTER = "customTimelineTagsFilter-V2";
export const CUSTOM_SUPPLY_YEAR_FILTER = "customSupplyYearFilter";
export const TIMELINE_PARAMETERS = "timelineParameters-V2";
export const TIMELINE_GROUPER = "timelineGrouper-V2";
export const FULL_TIMELINE = "fullTimeline";
export const CUSTOM_TIMELINE = "customTimeline";
export const CUSTOM_SUPPLY = "customSupply";
export const PORTFOLIO_DATE_FILTER = "Portfolio_Date_Filter";
export const SELECTED_SHIFTS = "selected-shifts";

// tags
export const BLOCK_TAG = "BLOCK";
export const MID_TAG = "MID";
export const START_TAG = "START";
export const FINISH_TAG = "FINISH";
export const MILESTONE_YES = "Yes";
export const SCENARIO_TAG = "SCENARIO";

// SCENARIO SOTRAGE
export const SCENARIO_STORAGE = "SCENARIO_STORAGE";

// Roles
export const USER_ADMIN = "admin";
export const USER_EDITOR = "editor";
export const ROLE_SYSTEM_ADMIN = "ROLE_SYSTEM_ADMIN";
export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_PROJECT_MANAGER = "ROLE_PROJECT_MANAGER";
export const ROLE_RESOURCE_MANAGER = "ROLE_RESOURCE_MANAGER";
export const ROLE_PORTFOLIO_MANAGER = "ROLE_PORTFOLIO_MANAGER";
export const ROLE_VIEWER = "ROLE_VIEWER";
export const ROLE_COLLABORATOR = "ROLE_COLLABORATOR";

export const ACTION_BUTTON_COLOR = "fourth btn-sm text-white font-weight-bold ";
export const ACTION_BUTTON_OUTLINE = "outline-fourth btn-sm font-weight-bold ";

// DIMENSIONS
export const OPEX = "Labor cost";
export const CAPEX = "Non-labor cost";
export const MAN_HOURS = "Manhours";
export const MAN_DAYS = "Mandays";
export const FTE = "FTE";

// Height below top page
export const INNER_HEIGHT_BELOW_TOP_PAGE = 96;
export const INNER_HEIGHT_BELOW_DOUBLE_ROW_TOP_PAGE = 126;
export const INNER_HEIGHT_BELOW_TOP_PAGE_AND_TABS = 127;

// ITEM TYPE
export const SCENARIO_BLOCK = "scenario_block";
export const LIBRARY_BLOCK = "library_block";
export const SCENARIO_ACTIVITY = "scenario_activity";
export const LIBRARY_ACTIVITY = "library_activity";
export const SCENARIO_GROUP = "scenario_group";
export const LIBRARY_GROUP = "library_group";
export const LIBRARY_CATEGORY = "library_category";
export const LIBRARY_STRUCTURE = "library_structure";

//ScheduleActivity
export const END_OF_BUSINESS_DAY = 17;

export const BUTTON_MODE_EMBEDDED = "BUTTON_MODE_EMBEDDED";

export const LAYOUT_ONCE = "LAYOUT_ONCE";
export const LAYOUT_ALWAYS = "LAYOUT_ALWAYS";
export const LAYOUT_NEVER = "LAYOUT_NEVER";

// Split array of values
export const CUSTOM_SPLIT = "/$&%";
export const CUSTOM_SPLIT_WITH_COMMA = ",/$&%,";

// Visual planning
export const ONE_DAY = "1 day";
export const THREE_DAYS = "3 days";
export const ONE_WEEK = "1 week";
export const TWO_WEEKS = "2 weeks";
export const ONE_MONTH = "1 month";

// scenario state
export const SYNC = "SYNC";
export const UNSYNC = "UNSYNC";

// page types
export const LIBRARY_PAGE = "LIBRARY_PAGE";
export const SCENARIO_PAGE = "SCENARIO_PAGE";
export const PROJECT_PAGE = "PROJECT_PAGE";

// USAGES TYPES FOR SIMULATION
export const NEW_USAGES = "NEW USAGES";
export const PORTFOLIO_AVAILABILITY = "PORTFOLIO AVAILABILITY";
export const NEW_AVAILABILITY = "NEW AVAILABILITY";

export const MIN_BOTTLENECK = 0;
export const MAX_BOTTLENECK = 20;
export const NULL_BOTTLENECK = -1;

// Resource types
export const TEAMS = "TEAMS";
export const COLLABORATOR = "COLLABORATOR";
export const GENERIC_NONLABOR = "GENERIC_NONLABOR";
export const SPECIFIC_NONLABOR = "SPECIFIC_NONLABOR";
export const PROJECT_BUFFER = "PROJECT_BUFFER";

export const LABOR = "LABOR";
export const NONLABOR = "NONLABOR";
export const DIMENSION = "DIMENSION";

// BUFFERING METHODS
export const NONE = "NONE";

export const UNCOMPRESSED_PROJECT_BUFFER_SCHEDULE =
  "UNCOMPRESSED_PROJECT_BUFFER_SCHEDULE";
export const COMPRESSED_PROJECT_BUFFER_SCHEDULE =
  "COMPRESSED_PROJECT_BUFFER_SCHEDULE";
export const CLASSIC_CRITICAL_CHAIN_SCHEDULE_COMPRESSION =
  "CLASSIC_CRITICAL_CHAIN_SCHEDULE_COMPRESSION";

// MONITORING TYPES
export const BY_DELAY = "BY_DELAY";
export const FIXED_CAPACITY = "FIXED_CAPACITY";

// SCHEDULER FILTERS
export const SCHEDULER_FILTER_DATES = "scheduler_filter_dates";
export const SCHEDULER_FILTER_ARE_TEAMS_VIEW =
  "scheduler_filter_are_teams_view";
export const SCHEDULER_FILTER_SELECTED_PROJECTS =
  "scheduler_filter_selected_projects";
export const SCHEDULER_FILTER_SELECTED_TEAMS =
  "scheduler_filter_selected_teams";
export const SCHEDULER_FILTER_DURATION_DRIVEN_BACKLOG_SELECTED =
  "scheduler_filter_duration_driven_backlog_selected";
export const MY_LOOK_AHEAD_PLAN_DATE_CHANGED =
  "MY_LOOK_AHEAD_PLAN_DATE_CHANGED";

// BRYNTUM TOOLS
export const TBAR_SPACER = "->";
export const TASKS = "TASKS";
export const REGULARS = "REGULARS";

export const RESOURCES = "RESOURCES";
export const SPECTRUM = "SPECTRUM";
export const PORTFOLIO = "portfolio";

export const ALL = "All";

// Non labor dimension resources column name
export const NON_LABOR_RESOURCES = "Non labor resources";

// confetti
export const SHOW_CONFETTI_EVENT = "show-confetti";

// Resource colors
export const RESOURCE_COLORS = {
  TEAMS: "blue",
  COLLABORATOR: "indigo",
  GENERIC_NONLABOR: "green",
  SPECIFIC_NONLABOR: "teal",
  DIMENSION: "lime",
};

// scenario AI events
export const AI_SCENARIO_ENRICH = "AI_SCENARIO_ENRICH";

// project ask AI events
export const SHOW_PROJECT_IMPORT_DIALOG = "SHOW_PROJECT_IMPORT_DIALOG";

// TaskBoard
export const TODO = "TODO";
export const IN_PROGRESS = "IN_PROGRESS";
export const REVIEW = "REVIEW";
export const DONE = "DONE";
export const LOW = "LOW";
export const MEDIUM = "MEDIUM";
export const HIGH = "HIGH";
export const PERSONAL = "Personal";
export const TASKS_DASHBOARD_SELECTION = "TASKS_DASHBOARD_SELECTION";
export const PROJECTS_DASHBOARD_SELECTION = "PROJECTS_DASHBOARD_SELECTION";
export const USER_TASKBOARD_DATE_FILTER = "User_Taskboard_Date_Filter";
export const USER_TASKBOARD_TEAM_FILTER = "User_Taskboard_Team_Filter";
export const USER_TASKBOARD_LATE_FILTER = "User_Taskboard_Late_Filter";
export const ADD_DEADLINE = "Add deadline";
export const USER_TASKBOARD_VIEW = "User_Taskboard_View";
export const USER_TASKBOARD_VIEW_EVENT = "USER_TASKBOARD_VIEW_EVENT";
export const USER_TASKBOARD_VIEW_KANBAN = "USER_TASKBOARD_VIEW_KANBAN";
export const USER_TASKBOARD_VIEW_GRID = "USER_TASKBOARD_VIEW_GRID";
export const USER_TASKBOARD_VIEW_SCHEDULER = "USER_TASKBOARD_VIEW_SCHEDULER";
export const TIME_9_AM = "T09:00:00";
export const TIME_5_PM = "T17:00:00";

// Hyppo page types
export const HYPPO_ERROR = "HYPPO_ERROR";
export const HYPPO_NOT_ALLOWED = "HYPPO_NOT_ALLOWED";

// Work Days Calendar
export const DAYS_OF_WEEK = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];
export const DAYS_OF_WEEK_ALT = [
  "SUNDAY",
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
];
export const HOLIDAY = "HOLIDAY";
export const EXCEPTION = "EXCEPTION";

export const GANTT_HIDE_COMPLETED_TASKS_FILTER = "HideCompletedTasksFilter";

export const PROJECTS_GRID_ARCHIVED_FILTER = "Projects_Grid_Archived_Filter";

export const MAXIMUM_SAFE_DURATION = 1200;

// Project entity types
export const PROJECT = "PROJECT";
export const PROGRAM = "PROGRAM";

// calendars hours
export const DEFAULT_START_OF_DAY = 9;
export const DEFAULT_FINISH_OF_DAY = 16;

export const VISUAL_PLANNING_VIEW_MODE_ENABLED =
  "VisualPlanningViewModeEnabled";

export const MILESTONE_TIMELINE_ELEMENT = "timeline-milestone";

// program view
export const PROGRAM_GANTT_VIEW = "gantt";
export const PROGRAM_TIMELINE_VIEW = "timeline";
export const PROGRAM_METRICS_VIEW = "metrics";
export const PROGRAM_TIMELINE_STYLE_ID = "program-timeline-style";

// workload page
export const WORKLOAD_FILTER_CHANGE = "WORKLOAD_FILTER_CHANGE";

// accuracy
export const DAILY = "DAILY";
export const WEEKLY = "WEEKLY";
export const MONTHLY = "MONTHLY";

// Resource Usage page
export const RESOURCE_USAGE_GRID_TYPE = "ResourceUsageGridType";
export const ARE_USAGES_CHANGED = "ARE_USAGES_CHANGED";
export const SAVE_USAGES = "SAVE_USAGES";
export const RESET_USAGES = "RESET_USAGES";

// project priority
export const PROJECT_PRIORITIES_VALUES = [
  { name: "MANDATORY", value: 0 },
  { name: "P1", value: 1 },
  { name: "P2", value: 2 },
];

export const EXEC_DATETIME_TIME_RANGE_ID = "exec-datetime-start";

export const MAX_CHARACTERS_NUMBER_FOR_COLUMN_NAME = 60;

export const BACKLOG_UPDATE_TYPE_RECORD = "RECORD";
export const BACKLOG_UPDATE_TYPE_CUSTOM_COLUMN = "CUSTOM_COLUMN";
export const BACKLOG_UPDATE_TYPE_EDITING = "EDITING";

export const SHARED = "SHARED";
export const SHARED_LABEL = "PUBLIC";
export const PRIVATE = "PRIVATE";

export const TO_BE_PLANNED = "TO_BE_PLANNED";


// Settings Page 

export const SETTINGS_KEY_MAPPINGS = {
  portfolioName: "Portfolio Name",
  monitoringType: "Monitoring Type",
  dateOfReview: "Date of Review",
  remainingMonthsCoefficient: "Remaining Months Coefficient",
  usageLaborAccuracyOption: "Labor Accuracy Option",
  usageNonLaborAccuracyOption: "Non Labor Accuracy Option",
  yearlyFTE: "Yearly FTE",
  maxBlocks: "Max Schedule Size",
  startDate: "Start Date",
  finishDate: "Finish Date",
  dailyFTE: "Daily FTE",
  dimensionLimit: "Custom Dimension Limit",
  isDecimalConsumption: "Decimal Consumption",
};

export const SETTINGS_KEY_DESCRIPTIONS = {
  portfolioName: "Choose a portfolio name", 
  monitoringType: "Set your portfolio monitoring type. Check documentation for more information", 
  dateOfReview: "Chose the date of Review", 
  remainingMonthsCoefficient: "Description for Remaining Months Coefficient",
  usageLaborAccuracyOption: "Set usage accuracy for labor resource", 
  usageNonLaborAccuracyOption: "Set usage accuracy for non-labor resource",
  yearlyFTE: "Number of hours per year for one FTE",
  dailyFTE: "Number of hours per day for one FTE",
  maxBlocks: "Number of blocks per project scenario",
  startDate: "Start date of default portfolio calendar",
  finishDate: "Finish date of default portfolio calendar", 
  dimensionLimit: "Number of custom dimensions for your portfolio",
  isDecimalConsumption: "Enable one decimal for custom dimensions",
};

export const SETTINGS_GENERAL_INFORMATIONS = ["portfolioName", "monitoringType"];
export const SETTINGS_PORTFOLIO_LIMITS_KEYS= ["maxBlocks", "startDate", "finishDate", "dimensionLimit"];
export const SETTINGS_RESOURCE_USAGE_ACCURACY_KEYS  = ["usageLaborAccuracyOption", "usageNonLaborAccuracyOption", "isDecimalConsumption"];
export const SETTINGS_FTE_KEYS= ["yearlyFTE","dailyFTE"];
export const SETTINGS_TASKFOCUS_KEYS = ['taskFocusSettings']

export const WORK_PACKAGE_GRID_FILTERED_DATA_IDS =
  "WORK_PACKAGE_GRID_FILTERED_DATA_IDS";

export const GANTT_TASK_FOCUS_FILTERS = "GANTT_TASK_FOCUS_FILTERS";
export const GANTT_TASK_FOCUS_FILTER_EVENT = "GANTT_TASK_FOCUS_FILTER_EVENT";
export const SELECTED_TASK_FOCUS_GANTT_CUSTOM_COLUMNS =
  "SELECTED_TASK_FOCUS_GANTT_CUSTOM_COLUMNS";
export const SELECTED_FULL_TIMELINE_CUSTOM_COLUMNS =
  "SELECTED_FULL_TIMELINE_CUSTOM_COLUMNS";
export const SELECTED_PROGRAM_GANTT_CUSTOM_COLUMNS =
  "SELECTED_PROGRAM_GANTT_CUSTOM_COLUMNS";
export const SELECTED_CUSTOM_WORKLOAD_CUSTOM_COLUMNS =
  "SELECTED_CUSTOM_WORKLOAD_CUSTOM_COLUMNS";

// Resource Name Showing (Task Focus)
export const SHOW_RESOURCE_NAME_KEY = "showResourceName";

export const TASKFOCUS_SNAP_FEATURE_KEY = "TASKFOCUS_SNAP_FEATURE_KEY";
export const TASKFOCUS_TIME_RESOLUTION = "TASKFOCUS_TIME_RESOLUTION";
export const TASKFOCUS_SHOW_NON_WORKING_TIME = "TASKFOCUS_SHOW_NON_WORKING_TIME";


// La Hague Constants
export const COLUMN_STATUT_AT = "Statut AT";
export const COLUMN_MP_CP = "MP / CP ?";

// tmp workaround for the list of allowed user to deactivate/activate task, explicitly
export const ALLOWED_RESOURCE_COORDINATOR = ["coordinateur.test@offolio.com"];

export const INSTRUCTIBLE_COLOR = "#BDD7EE";
export const NON_INSTRUCTIBLE_COLOR = "#FF0000";
export const INSTRUITE_COLOR = "#C6E084";

export const MP_COLOR = "#305496";
export const CP_COLOR = "#7030A0";
export const PRO_COLOR = "#92D050";
export const CO_COLOR = "#C65911";
